import React, { useCallback, useEffect, useRef, useState } from 'react';
import { StyleSheet } from 'react-native';
import { Box, Text, Heading, HStack, VStack, Button, Modal, Center, Divider, Stack, Skeleton } from 'native-base';
import ScaledImage from '../../Components/ScaledImage';
import Trans from '../../Components/Trans';
import FileFunctions from '../../../Libs/FileFunctions';
import GeneralAction from '../../../Actions/GeneralAction';
import { createIconSetFromFontello } from 'react-native-vector-icons';
import lineAwesomeConfig from '../../../Assets/Fontello/line-awesome-config.json';
import main from '../../../Assets/Styles/main.json';
import JobPromoAction from '../../../Actions/JobPromoAction';
import { useRecoilState } from 'recoil';
import JobCountState from '../../../Recoil/JobCountState';
import TranslationAction from '../../../Actions/TranslationAction';

const Icon = createIconSetFromFontello(lineAwesomeConfig);
const mainStyle = StyleSheet.create(main);

const JobCard = (props) => {

    const
        firstLoad = useRef(true),
        [logo, setLogo] = useState(null),
        [addressFirstLine, setAddressFirstLine] = useState(null),
        [addressSecondLine, setAddressSecondLine] = useState(null),
        [coordinates, setCoordinates] = useState(null),
        [alias, setAlias] = useState(''),
        [jobCount, setJobCount] = useRecoilState(JobCountState),
        [language, setLanguage] = useState(null)
        ;

    const onFirstLoad = useCallback(() => {
        const init = async () => {

            //get language
            let languageDB = await TranslationAction.getSelectedLanguage();
            setLanguage(languageDB);

            //get logo
            if (props.data.shift.project.client.logo) {
                let logoRes = await FileFunctions.download({ url: props.data.shift.project.client.logo });
                setLogo(logoRes);
            }

            let job = props.data;
            let client = job?.shift?.project?.client;
            if (null === client || undefined === client) client = job?.shift?.workSchedule?.client;

            if(job.shift && job.shift.position){
                setAlias(await GeneralAction.getAlias(job.shift.position, client.id));
            }

            //set address
            let endAddress = null;
            if (props.data.shift.address) {
                endAddress = props.data.shift.address;
            } else if (props.data.shift.project.address) {
                endAddress = props.data.shift.project.address;
            } else if (props.data.shift.project.client.addresses.length > 0) {
                for (let clientAddress of props.data.shift.project.client.addresses) {
                    if (clientAddress.enabled) {
                        endAddress = clientAddress;
                        break;
                    }
                }
            }

            //format address
            if (endAddress) {
                let textAddress = `${endAddress.street} ${endAddress.streetNumber}, ${endAddress.zip} ${endAddress.place}, `;
                let country = await GeneralAction.getCountry(endAddress.country);
                textAddress += country;
                setAddressFirstLine(`${endAddress.street} ${endAddress.streetNumber},`);
                setAddressSecondLine(`${endAddress.zip} ${endAddress.place}, ${country}`);
                /*setCoordinates({
                    lat: endAddress.coordinates.lat,
                    lng: endAddress.coordinates.lng
                });*/
            }
            else {
                setAddressFirstLine(false);
                setAddressSecondLine(false);
            }

            await JobPromoAction.setSeen(props.data.id);
            let newJobCount = await JobPromoAction.getUnseenCount();
            setJobCount(newJobCount);
        };
        init();
    }, [props, setLogo, setJobCount]);

    useEffect(() => {
        if (firstLoad.current) {
            firstLoad.current = false;
            onFirstLoad();
        }
    }, [firstLoad, onFirstLoad]);

    return (
        <Center>
            <Box
                style={{
                    userSelect: 'none',
                    borderRadius: 12,
                    backgroundColor: 'rgba(255, 255, 255, 1)',
                    borderColor: 'rgba(219, 220, 222, 0.6)',
                    borderWidth: 1,
                    borderStyle: 'solid',
                }}

                shadow={2}
                width={'100%'}
                justifyContent={"space-between"}
                maxWidth={'600'}
            >
                <VStack
                    space={2}
                    style={{
                        padding: 20,
                    }}
                >
                    <HStack
                        alignItems={'center'}
                        justifyContent={'space-between'}
                    >
                        <Box flexGrow={1} flexShrink={1}>
                            <Text>{language && GeneralAction.formatDateFull(props.data.shift.startOn, language.code)}</Text>
                        </Box>
                        <Box>
                            <Stack
                                direction={{
                                    base: 'column',
                                    md: 'row',
                                }}
                                space={1}
                            >
                                {props.data.shift.projectStartOn &&
                                    <Text style={[mainStyle.timeLabel, { marginRight: 5, backgroundColor: '#edf3ff', color: '#5A73E7' }]}>
                                        <Icon name={'car'} size={15} /> {GeneralAction.formatTime(props.data.shift.projectStartOn)}
                                    </Text>
                                }
                                <Text style={mainStyle.timeLabel}>
                                    <Icon name={'clock'} size={15} /> {GeneralAction.formatTime(props.data.shift.startOn)} - {GeneralAction.formatTime(props.data.shift.endOn)}
                                </Text>

                            </Stack>
                        </Box>
                    </HStack>
                    <Divider my="2" style={mainStyle.dividerStyle} />
                    <HStack alignItems={"center"}>
                        {logo && logo.uri &&
                            <ScaledImage width={30} uri={logo.uri} />
                        }
                        <Text style={{ marginLeft: 5 }}>{props.data.shift.project.client.name}</Text>
                    </HStack>
                    <Heading><Text style={{ color: '#00AAFF' }}>{props.data.shift.project.name}</Text></Heading>
                    <Heading>{alias}</Heading>

                    {addressFirstLine === null && addressSecondLine === null &&
                        <VStack space={2}>
                            <Skeleton.Text lines={2} />
                        </VStack>
                    }

                    {addressFirstLine && addressSecondLine &&
                        <Box style={{ flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center' }} >
                            <Icon name={'map-marker'} size={20} />
                            <Text style={{ flexWrap: 'nowrap' }} stytextDecoration={'underline'}>{addressFirstLine}</Text>
                            <Text style={{ flexWrap: 'nowrap' }} stytextDecoration={'underline'}>{addressSecondLine}</Text>
                        </Box>
                    }

                </VStack>
                <HStack>
                    <Button
                        style={{ backgroundColor: "#ffcaca", width: '50%', borderRadius: 0, borderBottomLeftRadius: 12 }}
                        onPress={() => { props.onSwipedLeft() }}
                    >
                        <Icon color={"#FF6363"} name='times' size={20} />
                    </Button>
                    <Button
                        style={{ backgroundColor: "#c5ebff", width: '50%', borderRadius: 0, borderBottomRightRadius: 12 }}
                        onPress={() => { props.onSwipedRight() }}
                    >
                        <Icon color={"#00AAFF"} name='check' size={20} />
                    </Button>
                </HStack>
            </Box>
        </Center>
    );
};

export default JobCard;
