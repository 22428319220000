import React, {useState, useEffect} from 'react';
import {StyleSheet} from 'react-native';
import {Box, Divider, Text, Heading, HStack, Stack} from 'native-base';
import GeneralAction from '../../../../Actions/GeneralAction';
import main from '../../../../Assets/Styles/main.json';
import TranslationAction from '../../../../Actions/TranslationAction';
import {createIconSetFromFontello} from 'react-native-vector-icons';
import lineAwesomeConfig from '../../../../Assets/Fontello/line-awesome-config.json';

const mainStyle = StyleSheet.create(main);
const Icon = createIconSetFromFontello(lineAwesomeConfig);

const Job = props => {
  const job = props.job;

  //let [logo, setLogo] = useState('');
  let [language, setLanguage] = useState(null);
  let [address, setAddress] = useState([]);
  let [alias, setAlias] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      // Get language
      setLanguage(await TranslationAction.getSelectedLanguage());

      let client = job?.shift?.project?.client;
      if (null === client || undefined === client)
        client = job?.shift?.workSchedule?.client;

      if (job.shift && job.shift.position) {
        setAlias(await GeneralAction.getAlias(job.shift.position, client.id));
      }

      // Get address
      if (job.shift && job.shift.address) {
        // By default, pick address of shift
        setAddress(job.shift.address);
      } else if (job.shift && job.shift.project && job.shift.project.client) {
        if (client.addressBilling) {
          // Pick billing address of client if we don't have a shift address
          setAddress(client.addressBilling);
        } else if (client.addresses && client.addresses[0]) {
          // Pick first address of client if no billing address
          setAddress(client.addresses[0]);
        }
      }
      // Get logo company
      /*let logoResult = null;
            if (job.shift.project.client.logo) {
              logoResult = await APIAction.request({method: 'get', url: job.shift.project.client.logo});
            }
      
            // Default style/source
            let imageStyle = {
              resizeMode: "contain",
              height: 100,
              width: 100
            };
      
            let source = require('../../../../Assets/Images/logo.png');
      
            // Set path of logo as source
            if (logoResult) {
              source = {
                uri: logoResult.path
              };
            }
      
            // Create logo element
            setLogo(
              <Image
                style={imageStyle}
                alt={job.shift.project.client.name}
                source={source}
              />
            ); */
    };
    fetchData();
  }, [job]);

  return (
    <Box style={{...mainStyle.boxItem, ...props.style}}>
      <Box style={{flex: 1}}>
        <HStack alignItems={'center'} justifyContent={'space-between'}>
          <Box flexGrow={1} flexShrink={1}>
            <Text>{language && GeneralAction.formatDateFull(job.shift.startOn, language.code)}</Text>
          </Box>
          <Box>
            <HStack>
              {job.shift.projectStartOn && (
                <Text
                  style={[
                    mainStyle.timeLabel,
                    {
                      marginRight: 5,
                      backgroundColor: '#edf3ff',
                      color: '#5A73E7',
                    },
                  ]}>
                  <Icon name={'car'} size={15} />{' '}
                  {GeneralAction.formatTime(job.shift.projectStartOn)}
                </Text>
              )}
              <Text style={mainStyle.timeLabel}>
                <Icon name={'clock'} size={15} />{' '}
                {GeneralAction.formatTime(job.shift.startOn)} -{' '}
                {GeneralAction.formatTime(job.shift.endOn)}
              </Text>
            </HStack>
          </Box>
        </HStack>
        <Divider my="2" style={mainStyle.dividerStyle} />
        <Stack
          style={{
            width: '100%',
            justifyContent: 'space-between',
          }}
          space={2}
          direction={{
            base: 'column',
            md: 'row',
          }}>
          {/*<Box style={mainStyle.companyLogoWrapper}>
              {logo}
            </Box> */}
          <Box>
            <Heading
              style={[
                mainStyle.mediumTitle,
                {
                  fontSize: 20,
                },
              ]}>
              {alias}{' '}
              <Text style={{color: '#00AAFF'}}>{job.shift.project?.name}</Text>
            </Heading>
            {/*<View style={{flexDirection: 'row', alignItems: 'flex-start'}}>*/}
            {/*  <Text style={{fontSize: 18, lineHeight: 30, color: '#556ee6', fontWeight: '700'}}>{ wage }/hr</Text>*/}
            {/*  <Text style={{fontSize: 18, lineHeight: 20, color: '#556ee6', fontWeight: '700'}}>*</Text>*/}
            {/*</View>*/}
          </Box>
          <Box>
            <Text style={mainStyle.companyName}>
              {job.shift.project?.client.name}
              {job.shift.workSchedule?.client.name}
            </Text>
          </Box>
        </Stack>
        {address && address.length > 0 && (
          <Box
            style={{
              paddingHorizontal: 10,
              flexDirection: 'row',
              display: 'flex',
              alignItems: 'center',
            }}>
            <Icon
              name={'map-marker'}
              size={20}
              style={{color: 'rgba(0,0,0,0.5)', width: '10%'}}
            />
            {
              <Text>
                {address.name}, {address.street + ' ' + address.streetNumber},{' '}
                {address.zip + ' ' + address.place}
              </Text>
            }
          </Box>
        )}

        {/*<Box style={{flexDirection:'row', width:'100%',alignItems:'center',paddingTop:5,paddingBottom:5}}>
                    <Box style={mainStyle.friendsList}>
                        <Image
                            style={[mainStyle.friendItem, {zIndex:5,marginRight:-15}]}
                            source={require('../../Assets/Images/user-1.png')}
                        />
                        <Image
                            style={[mainStyle.friendItem ]}
                            source={require('../../Assets/Images/user-2.png')}
                        />
                    </Box>
                    <Box style={mainStyle.shareWrapper}>
                        <Button style={[mainStyle.yellowButton]}>
                            <Text style={{color: '#000'}}> Share</Text>
                        </Button>
                    </Box>
                </Box>*/}
      </Box>
    </Box>
  );
};

export default Job;
