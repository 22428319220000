import React, { useCallback, useEffect, useRef, useState } from 'react';
import { StyleSheet, useWindowDimensions } from 'react-native';
import { Box, Text, Heading, Button, Divider, Center, Spinner, VStack, HStack, Stack, useTheme } from 'native-base';
import { createIconSetFromFontello } from 'react-native-vector-icons';
import lineAwesomeConfig from '../../Assets/Fontello/line-awesome-config.json';
import main from '../../Assets/Styles/main.json';
import AppContainer from '../Components/AppContainer';
import Trans from '../Components/Trans';
import APIAction from '../../Actions/APIAction';
import GeneralAction from '../../Actions/GeneralAction';
import FileFunctions from '../../Libs/FileFunctions';
import ScaledImage from '../Components/ScaledImage';
import { v4 as uuidv4 } from 'uuid';
import Mapbox from '../../Libs/Mapbox';
import JobPromoAction from '../../Actions/JobPromoAction';
import ShareMessage from '../../Libs/ShareMessage';
import TranslationAction from '../../Actions/TranslationAction';

const Icon = createIconSetFromFontello(lineAwesomeConfig);
const mainStyle = StyleSheet.create(main);

const JobApplyScreen = ({ navigation, route }) => {


    const
        firstLoad = useRef(true),
        { colors } = useTheme(),
        [waiting, setWaiting] = useState(false),
        [job, setJob] = useState(null),
        [logo, setLogo] = useState(null),
        [windowSizeUuid, setWindowSizeUuid] = useState(uuidv4()),
        { height, width } = useWindowDimensions(),
        [address, setAddress] = useState(null),
        [coordinates, setCoordinates] = useState(null),
        [image, setImage] = useState(null),
        [alias, setAlias] = useState(''),
        [clothingAndSupplyRules, setClothingAndSupplyRules] = useState(false),
        [share, setShare] = useState(null),
        [waitShare, setWaitShare] = useState(false),
        [language, setLanguage] = useState(null)
        ;


    const onFirstload = useCallback(() => {
        const init = async () => {

            //get language
            let languageDB = await TranslationAction.getSelectedLanguage();
            setLanguage(languageDB);

            //get shift
            let apiJob = await APIAction.request({
                method: 'GET',
                url: `/api/job_promos/${route.params.id}`,
                cache: false
            });

            //get logo
            if (apiJob.shift.project.client.logo) {
                let logoRes = await FileFunctions.download({ url: apiJob.shift.project.client.logo });
                setLogo(logoRes);
            }

            //set address
            let endAddress = null;
            if (apiJob.shift.address) {
                endAddress = apiJob.shift.address;
            } else if (apiJob.shift.project.address) {
                endAddress = apiJob.shift.project.address;
            } else if (apiJob.shift.project.client.addresses.length > 0) {
                for (let clientAddress of apiJob.shift.project.client.addresses) {
                    if (clientAddress.enabled) {
                        endAddress = clientAddress;
                        break;
                    }
                }
            }

            //format address
            if (endAddress) {
                let textAddress = `${endAddress.street} ${endAddress.streetNumber}, ${endAddress.zip} ${endAddress.place}, `;
                let country = await GeneralAction.getCountry(endAddress.country);
                textAddress += country;
                setAddress(textAddress);
                setCoordinates({
                    lat: endAddress.coordinates.lat,
                    lng: endAddress.coordinates.lng
                });
            }

            setJob(apiJob);


            //get image
            if (apiJob.image) {
                let apiImage = await FileFunctions.download({ url: apiJob.image });
                if (apiImage) setImage(apiImage);
            }

            let client = apiJob?.shift?.project?.client;
            if (null === client || undefined === client) client = apiJob?.shift?.workSchedule?.client;

            if(apiJob.shift && apiJob.shift.position){
                setAlias(await GeneralAction.getAlias(apiJob.shift.position, client.id));
            }

            // Get clothiong and supply rules
            let clothingAndSupplyRules = await APIAction.request({
                method: 'get', url: `/api/get/clothing_and_supply_rules/${apiJob?.shift?.project?.id}`, cache: false
            });
            setClothingAndSupplyRules(clothingAndSupplyRules);

            let jobPromo = await JobPromoAction.get(apiJob.id);
            if (!jobPromo) await JobPromoAction.sync();
            await JobPromoAction.setSeen(route.params.id);
            await JobPromoAction.setOpen(route.params.id);

        };
        init();
    }, [route.params.id]);

    // If tracking code provided
    useEffect(() => {
        if (route.params.trackingCode) {
            const init = async () => {
                await APIAction.request({
                    method: 'POST',
                    url: '/api/job_promo_share_link_receiveds',
                    body: {},
                    params: {
                        trackingCode: route.params.trackingCode
                    },
                    cache: false
                });
            };
            init();
        }
    }, [route.params.trackingCode]);

    // Share job
    const onShare = async () => {

        setWaitShare(true);

        //request joblink
        let jobLink = await APIAction.request({
            method: 'POST',
            url: `/api/job_promo_share_links`,
            body: {
                'jobPromo': job['@id']
            },
            cache: false
        });

        //get url
        let url = jobLink.url;

        let text = job.shift.project.name + '\n';
        text += job.shift.project.client.name + '\n';
        text += alias + '\n';
        text += GeneralAction.formatDateFull(job.shift.startOn, language.code) + ' ';
        text += GeneralAction.formatTime(job.shift.startOn) + ' - ' + GeneralAction.formatTime(job.shift.endOn) + '\n';

        setShare(<ShareMessage key={uuidv4()} title={'Job: ' + job.shift.project.name} text={text} url={url} onDone={() => setWaitShare(false)} />);
    };

    useEffect(() => {
        if (firstLoad.current) {
            firstLoad.current = false;
            onFirstload();
        }
    }, [onFirstload]);

    useEffect(() => {
        if (width && height) {
            setWindowSizeUuid(uuidv4());
        }
    }, [width, height]);

    return (
        <AppContainer>
            {/*<Box style={mainStyle.pageTitleWrapper}>*/}
            {/*    <Box style={{ flexGrow: 1 }}>*/}
            {/*        <Heading style={mainStyle.pageTitle}><Trans>Vacature</Trans></Heading>*/}
            {/*    </Box>*/}
            {/*</Box>*/}

            {job ?
                <>
                    <Box style={mainStyle.boxItemVertical}>
                        <HStack
                            alignItems={'center'}
                            justifyContent={'space-between'}
                        >
                            <Box flexGrow={1} flexShrink={1}>
                                <Text>{language && GeneralAction.formatDateFull(job.shift.startOn, language.code)}</Text>
                            </Box>
                            <Box>
                                <HStack>
                                    { job.shift.projectStartOn &&
                                    <Text style={[mainStyle.timeLabel, {marginRight: 5, backgroundColor: '#edf3ff', color: '#5A73E7'}]}>
                                        <Icon name={'car'} size={15} /> {GeneralAction.formatTime(job.shift.projectStartOn)}
                                    </Text>}
                                    <Text style={mainStyle.timeLabel}>
                                        <Icon name={'clock'} size={15} /> {GeneralAction.formatTime(job.shift.startOn)} - {GeneralAction.formatTime(job.shift.endOn)}
                                    </Text>
                                </HStack>
                            </Box>
                        </HStack>
                        <Divider my="2" style={mainStyle.dividerStyle} />
                        <Stack
                            direction={{
                                md: 'row',
                                base: 'column'
                            }}
                            space={{
                                md: 2,
                                base: 2
                            }}
                        >
                            <VStack flex={1} flexGrow={1}>
                                <HStack alignItems={"center"}>
                                    {logo && logo.uri &&
                                    <ScaledImage width={30} uri={logo.uri} />
                                    }
                                    <Text style={{marginLeft: 5}}>{job.shift.project.client.name}</Text>
                                </HStack>
                                <Heading><Text style={{color: '#00AAFF'}}>{job.shift.project.name}</Text></Heading>
                                <Heading>{alias}</Heading>
                                { job.shift.projectStartOn &&
                                    <VStack style={{marginVertical: 5}}>
                                        <Text>
                                            <Icon name={'car'} size={15} /> <Trans>Afspraak om</Trans> {GeneralAction.formatTime(job.shift.projectStartOn)}
                                        </Text>
                                        <Text>
                                            <Icon name={'clock'} size={15} /> <Trans>Uren shift: </Trans> {GeneralAction.formatTime(job.shift.startOn)} - {GeneralAction.formatTime(job.shift.endOn)}
                                        </Text>
                                    </VStack>
                                    }

                                {job.description &&
                                <Box style={{verticalMargin: 10}}>
                                    <Text style={mainStyle.infoText}>{job.description}</Text>
                                </Box>
                                }

                                <Box style={{verticalMargin: 10, alignItems: 'flex-start'}}>
                                    <Button
                                        isLoading={waitShare}
                                        variant={'subtle'}
                                        leftIcon={<Icon color={colors['primary']['600']} name={'share'} size={20} />}
                                        borderRadius={'full'}
                                        onPress={() => onShare()}
                                    >
                                        <Text color={colors['primary']['600']}><Trans>Share</Trans></Text>
                                    </Button>
                                </Box>

                                {/* {job.shift.project.projectInfo &&
                                <Box style={{verticalMargin: 10}}>
                                    <Text style={mainStyle.infoText}>{job.shift.project.projectInfo}</Text>
                                </Box>
                                } */}

                            </VStack>
                            <VStack width={{
                            base: '100%',
                            md: '50%'
                            }}>
                                <Box style={{backgroundColor: '#f9f9f9', padding: 15, borderRadius: 5, flexGrow: 1}}>
                                    {clothingAndSupplyRules.clothingRules?.length > 0 &&
                                        <>
                                        <Text style={[mainStyle.infoText, {fontWeight: 'bold', fontSize: 12, marginBottom: 5 }]}><Trans>Dresscode: </Trans></Text>
                                        <Box style={{flex: 1, flexDirection: 'row', flexWrap: 'wrap'}}>
                                            {clothingAndSupplyRules.clothingRules?.map((clothingRule) => {
                                                return <Text style={[mainStyle.timeLabel, {marginRight: 5, backgroundColor: '#edf3ff', color: '#5A73E7', marginBottom: 5, fontSize:11}]}>{clothingRule}</Text>
                                            })}
                                        </Box>
                                        </>
                                    }
                                    {clothingAndSupplyRules.supplyRules?.length > 0 &&
                                        <>
                                        <Text style={[mainStyle.infoText, {fontWeight: 'bold', fontSize: 12, marginBottom: 5, marginTop: 7 }]}><Trans>Voorzie ook zeker volgende zaken: </Trans></Text>
                                        <Box style={{flex: 1, flexDirection: 'row'}}>
                                            {clothingAndSupplyRules.supplyRules?.map((supplyRule) => {
                                                return <Text style={[mainStyle.timeLabel,{marginRight: 5, marginBottom: 5, fontSize:11}]}>{supplyRule}</Text>
                                            })}
                                        </Box>
                                        </>
                                    }
                                    {/* Project info */}
                                    {job.shift.project.projectInfo &&
                                        <>
                                            <Text style={[mainStyle.infoText, {fontWeight: 'bold', fontSize: 12, marginBottom: 5, marginTop: 7 }]}><Trans>Extra info:</Trans></Text>
                                            <Text style={mainStyle.infoText}>{job.shift.project.projectInfo}</Text>
                                        
                                        </>
                                    }
                                {/* {image &&
                                    <Box style={mainStyle.imagePreview}>
                                        <Center>
                                            <ScaledImage
                                                key={`job-img-${windowSizeUuid}`}
                                                style={{ borderRadius: 10 }}
                                                maxHeight={300}
                                                maxWidth={600}
                                                width={width - 100}
                                                uri={image.uri}
                                            />
                                        </Center>
                                    </Box>
                                } */}
                                </Box>
                            </VStack>
                        </Stack>
                        {/*<Box style={{ flexDirection: 'row', alignItems: 'center', width: '100%', paddingVertical: 10 }}>
                            <Box>
                                <Text style={{ fontSize: 18, color: '#556ee6', fontWeight: '700' }}>€ 11,14 / hr</Text>
                            </Box>
                            <Box style={{ flex: 1, paddingLeft: 10 }}>
                                <Text style={{ fontSize: 11, color: 'rgba(0,0,0,0.8)' }}>- total € 334,20 (avg. payment in 26 hrs.)</Text>
                            </Box>
                    </Box>*/}


                    </Box>





                    {address &&
                        <Box style={mainStyle.boxItemVertical}>
                            <VStack
                                width={'100%'}
                                space={2}
                            > 
                            <HStack>
                                <Heading style={mainStyle.mediumTitle}><Trans>Location</Trans>: </Heading><Text flexGrow={1} flexShrink={1}>{address}</Text>
                            </HStack>
                            {coordinates &&
                                <Mapbox coordinate={[parseFloat(coordinates.lng), parseFloat(coordinates.lat)]} zoom={13} style={{ height: 300, width: '100%' }} />
                            }
                                
                            </VStack>
                        </Box>
                    }
                    <HStack space={2}>
                        <Button
                            isDisabled={waiting}
                            isLoading={waiting}
                            flex={1}
                            backgroundColor={'#ffcaca'}
                            style={{marginBottom: 15}}
                            onPress={() => {
                                const init = async () => {
                                    setWaiting(true);
                                    await JobPromoAction.setRefused(job.id);
                                    if (route.params.nextId)
                                        await JobPromoAction.setOpen(route.params.nextId);
                                    GeneralAction.toast('success', <Trans>Job refused</Trans>);
                                    await GeneralAction.sleep(1000);
                                    navigation.navigate('Swiper');
                                };
                                init();
                            }}
                        >
                            <Icon color={"#FF6363"} name='times' size={20} />
                        </Button>
                        <Button
                            isDisabled={waiting}
                            isLoading={waiting}
                            flex={1}
                            variant={'subtle'}
                            style={{marginBottom: 15}}
                            onPress={() => {
                                const init = async () => {
                                    setWaiting(true);
                                    await JobPromoAction.setAccepted(job.id);
                                    if (route.params.nextId)
                                        await JobPromoAction.setOpen(route.params.nextId);
                                    GeneralAction.toast('success', <Trans>Job accepted</Trans>);
                                    await GeneralAction.sleep(1000);
                                    navigation.navigate('Swiper');
                                };
                                init();
                            }}
                        >
                            <Icon color={"#00AAFF"} name='check' size={20} />
                        </Button>
                    </HStack>
                    {/*<Box style={mainStyle.boxItemVertical}>
                        <Box style={{ width: "100%" }}>
                            <Heading style={mainStyle.mediumTitle}>Friends who work on this job:</Heading>

                            <Pressable
                                onPress={() => {
                                    navigation.navigate('/');
                                }}
                            >
                                <Box style={{ paddingVertical: 5, alignItems: 'center', flexDirection: "row" }}>
                                    <Box style={{ width: 60 }}>
                                        <Image size={50}
                                            style={{ borderRadius: 50 }}
                                            source={require('../../Assets/Images/user-1.png')}
                                        />
                                    </Box>
                                    <Box style={{ flex: 1 }}>
                                        <Heading style={{ fontSize: 15, fontWeight: '500' }}>Wim Schiettecat</Heading>
                                    </Box>
                                    <Box style={{ width: '8%', paddingStart: 10 }}>
                                        <Icon name={'angle-right'} size={20} style={{ color: 'rgba(0,0,0,0.5)' }} ></Icon>
                                    </Box>
                                </Box>
                            </Pressable>
                            <Pressable
                                onPress={() => {
                                    navigation.navigate('/');
                                }}
                            >
                                <Box style={{ paddingVertical: 5, alignItems: 'center', flexDirection: "row" }}>
                                    <Box style={{ width: 60 }}>
                                        <Image size={50}
                                            style={{ borderRadius: 50 }}
                                            source={require('../../Assets/Images/user-2.png')}
                                        />
                                    </Box>
                                    <Box style={{ flex: 1 }}>
                                        <Heading style={{ fontSize: 15, fontWeight: '500' }}>Vincent Dierickx</Heading>
                                    </Box>
                                    <Box style={{ width: '8%', paddingStart: 10 }}>
                                        <Icon name={'angle-right'} size={20} style={{ color: 'rgba(0,0,0,0.5)' }} ></Icon>
                                    </Box>
                                </Box>
                            </Pressable>

                        </Box>
                            </Box>*/}


                </>
                :
                <Spinner size={'lg'} />
            }
            {share}
        </AppContainer>
    );
}

export default JobApplyScreen;
